import React, { useEffect, useRef, useState } from 'react';

// styles
import './Map.scss';

function Map(props) {
	// refs
	const refMap = useRef();
	const refMarkers = useRef([]);

	// props
	const { geolocation, geolocationTitle, hasHeader, isMapsLoaded, lat, lng, markers, setSelectedRoom } = props;

	// state
	const [map, setMap] = useState(null);
	const [markerUser, setMarkerUser] = useState(null);

	let mapClasses = '';
	let mapMods = '';

	if(props.classes) {
		for(let i = 0; i < props.classes.length; i++) {
			mapClasses += `${props.classes[i]} `;
		}
	}

	if(props.mods) {
		for(let i = 0; i < props.mods.length; i++) {
			mapMods += ` map--${props.mods[i]}`;
		}
	}

	function clearMarkers() {
		// for each marker
		for(let i = 0; i < refMarkers.current.length; i++) {
			// remove marker from map
			refMarkers.current[i].setMap(null);
		}

		refMarkers.current = [];
	}

	window.updateRoom = (room) => {
		// scroll to top of page
		window.scrollTo(0, 0);

		// decode and parse room string
		room = decodeURIComponent(room);
		room = JSON.parse(room);

		// set selected room
		setSelectedRoom(room);
	}

	useEffect(() => {
		if(isMapsLoaded && lat && lng) {
			// construct map
			const googleMap = new window.google.maps.Map(refMap.current, {
				mapTypeControlOptions: {
		      mapTypeIds: []
		    },
				streetViewControl: false,
				center: {
					lat: lat,
					lng: lng
				},
				zoom: 15,
				scrollwheel: false
			});

			if(geolocation) {

				// construct marker
				const googleMarkerUser = new window.google.maps.Marker({
					animation: window.google.maps.Animation.DROP,
					position: {
						lat: geolocation.lat,
						lng: geolocation.lng
					},
					map: googleMap,
					icon: {
				    url: '/img/marker-user.png',
				    scaledSize: new window.google.maps.Size(27, 43)
				  },
					title: (geolocationTitle) ? geolocationTitle : 'You Are Here'
				});

				setMarkerUser(googleMarkerUser);
			}

			setMap(googleMap);
		}
	}, [geolocation, geolocationTitle, isMapsLoaded, lat, lng]);

	useEffect(() => {

		// vars
		let infoWindow;

		function addMarkerClickEvent(o) {
			// add click event to marker
			o.marker.addListener('click', function() {
				let infoWindowContent = '';

				if(o.room) {
					let roomUsersNum = '';

					if(o.room.users.length > 0) {
						const userString = (o.room.users.length > 1) ? 'Users' : 'User';
						roomUsersNum = `<div class="infowindow__users">${o.room.users.length} Active ${userString}</div>`;
					}

					infoWindowContent = `<div class="map__infowindow"><h3 class="infowindow__title">${o.room.name}</h3>${roomUsersNum}<button type="button" class="btn btn--full btn--small" onClick=updateRoom('${encodeURIComponent(JSON.stringify(o.room))}')>Join Chat</button></div>`;
				}

				if(o.user) {
					let userDistance = '';

					if(o.user.distance) {
						const distance = (o.user.distance > 1) ? Math.round(o.user.distance) : o.user.distance.toFixed(1);
						userDistance = `<div class="infowindow__users">${distance} KM</div>`;
					}
					infoWindowContent = `<div class="map__infowindow"><h3 class="infowindow__title">${o.user.username}</h3>${userDistance}</div>`;
				}

				// close infoWindow if one exists
				if(infoWindow) {
					infoWindow.close();
				}

				// create info window
				infoWindow = new window.google.maps.InfoWindow({
					content: infoWindowContent
				});

				infoWindow.open(map, o.marker);
			});
		}

		// clear markers
		clearMarkers();

		if(map && markers) {
			const bounds = new window.google.maps.LatLngBounds();

			if(markerUser) {
				// update bounds to include markerUser
				bounds.extend(markerUser.position);
			}

			// for each room
			for(let i = 0; i < markers.length; i++) {

				if(markers[i].location) {

					if(markers[i].location.lat && markers[i].location.lng) {

						// construct marker
						const marker = new window.google.maps.Marker({
							animation: window.google.maps.Animation.DROP,
					    position: {
								lat: markers[i].location.lat,
								lng: markers[i].location.lng
							},
					    map: map,
					    title: (markers[i].username) ? markers[i].username : markers[i].name
					  });

						let addMarkerOptions = {
							marker: marker
						};

						if(markers[i].users) {
							addMarkerOptions['room'] = markers[i];
						}

						if(markers[i].username) {
							addMarkerOptions['user'] = markers[i];
						}

						addMarkerClickEvent(addMarkerOptions);

						if(marker.position) {
							// update bounds to include marker
							bounds.extend(marker.position);
						}

						// add marker to array
						refMarkers.current.push(marker);
					}
				}
			}
			// fit map to bounds
			map.fitBounds(bounds);
		}
	}, [map, markerUser, markers]);

	const renderMapHeader = () => {
		if(markers && hasHeader) {
			return (
				<header className="map__header">
					<span>{markers.length}</span> {(markers.length > 1) ? 'Places' : 'Place'}
				</header>
			)
		}
		return false;
	}

  return (
    <div className={`${mapClasses}map${mapMods}`}>
			{renderMapHeader()}
			<div className="map__map" ref={refMap}></div>
    </div>
  );
}

export default Map;
