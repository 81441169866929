import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

// components
import ChatHeader from '../../components/ChatHeader/ChatHeader.js';
import Map from '../../components/Map/Map.js';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import Viewport from '../../components/Viewport/Viewport.js';

// styles
import './Chat.scss';

function Chat(props) {

	// props
	const { isMapsLoaded, location, mapGeolocation, messages, notificationsEnabled, setMessages, setSelectedRoom, socket, users } = props;

	// refs
  const refViewportList = useRef();

	// state
	const [isTyping, setIsTyping] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isMapOpen, setIsMapOpen] = useState(false);

	// vars
	let mapClasses = ['chat__map'];
	let place;
	let user;

	// define place and user if location state exists
	if(location.state) {
		place = (location.state.place) ? JSON.parse(location.state.place) : null;
		user = (location.state.user) ? JSON.parse(location.state.user) : null;
	}

	function addMessage(msg) {
		// emit chat message to server
		socket.emit('add', msg);
	}

	function leaveRoom(user, room) {
		// leave chat room
		socket.emit('leave_room', user, room.id);

		// empty messages
		setMessages([]);

		if(notificationsEnabled) {
			new Notification(`Goodbye ${user.username}!`, {
				body: `You left ${room.name}`,
				icon: room.image
			});
		}
	}

  useEffect(() => {

		if(user && place) {

			// listen for add message event
	    socket.on('add', function(msg) {
				// update messages
				setMessages((messages) => [...messages, msg]);

				if(refViewportList.current) {
					refViewportList.current.scrollTop = refViewportList.current.scrollHeight;
				}
			});

			// join chat room
	    socket.emit('join', place, user);

			if(notificationsEnabled) {
				new Notification(`Hello ${user.username}!`, {
					body: `You joined ${place.name}`,
					icon: place.image
				});
			}
		}

		// unmount
		return () => {
			socket.off('add');
		}

  }, [notificationsEnabled, setMessages]);

	if(!location.state) {
		return (
			<Redirect to="/" />
		);
	}

	if(isMapOpen) {
		mapClasses.push('chat__map--active');
	}

  return (
    <section className="chat">
			<ChatHeader
				isMapOpen={isMapOpen}
				isSidebarOpen={isSidebarOpen}
				place={place}
				setIsMapOpen={setIsMapOpen}
				setIsSidebarOpen={setIsSidebarOpen}
			/>
      <Sidebar
				isMapOpen={isMapOpen}
				isTyping={isTyping}
				isSidebarOpen={isSidebarOpen}
				leaveRoom={leaveRoom}
				place={place}
				setIsMapOpen={setIsMapOpen}
				setIsSidebarOpen={setIsSidebarOpen}
				user={user}
				users={users}
			/>
      <Viewport
				addMessage={addMessage}
				isSidebarOpen={isSidebarOpen}
				messages={messages}
				place={place}
				refViewportList={refViewportList}
				setIsSidebarOpen={setIsSidebarOpen}
				setIsTyping={setIsTyping}
				user={user}
			/>
			<Map
				classes={mapClasses}
				geolocation={(place) ? place.location : null}
				geolocationTitle={(place) ? place.name : null}
				isMapsLoaded={isMapsLoaded}
				lat={(mapGeolocation) ? mapGeolocation.lat : null}
				lng={(mapGeolocation) ? mapGeolocation.lng : null}
				setSelectedRoom={setSelectedRoom}
				hasHeader={false}
				markers={users}
			/>
    </section>
  );
}

export default withRouter(Chat);
