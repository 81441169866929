import React from 'react';

// components
import Button from '../../components/Button/Button.js';
import Footer from '../../components/Footer/Footer.js';
import Header from '../../components/Header/Header.js';

// utils
import { cleanHtml } from '../../utils.js';

// styles
import './Page.scss';

function Page(props) {

	const { content } = props;

	const renderBtns = () => {
		if(content.btns) {
			const btnListItems = content.btns.map((item, key) => {
				return (
					<li>
						<Button 
							href={item.url}
							mods={item.mods}
							text={item.title}
						/>
					</li>
				);
			});

			return (
				<ul className="page__btns">
					{btnListItems}
				</ul>
			);
		}
		return false;
	}

  return (
		<>
			<Header />
	    <section className="page">
				<header className="page__header">
					<div className="header__wrap wrap">
						<div className="page__intro section">
							<h1 className="header__title">
								{content.title}
							</h1>
						</div>
					</div>
				</header>
				<div className="page__content section">
		      <div className="content__wrap wrap">
						<div className="page__copy">
							{cleanHtml(content.copy)}
						</div>
						{renderBtns()}
					</div>
				</div>
	    </section>
			<Footer />
		</>
  );
}

export default Page;
