import React from 'react';
import { withRouter} from 'react-router-dom';

// components
import Button from '../Button/Button.js';

// utils
import { truncate } from '../../utils.js';

// icons
import { ReactComponent as IconClose } from '../../icons/material/clear.svg';
import { ReactComponent as IconMap } from '../../icons/material/map.svg';
import { ReactComponent as IconMenu } from '../../icons/material/menu.svg';

// styles
import './ChatHeader.scss';

function ChatHeader(props) {

	const iconMap = (props.isMapOpen) ? <IconClose /> : <IconMap />;
	const iconMenu = (props.isSidebarOpen) ? <IconClose /> : <IconMenu />;

	function toggleMap() {
		props.setIsSidebarOpen(false);
		props.setIsMapOpen(!props.isMapOpen);
	}

	function toggleSidebar() {
		props.setIsMapOpen(false);
		props.setIsSidebarOpen(!props.isSidebarOpen);
	}

	// prevent rendering if place does not exist
	if(!props.place) {
		return false;
	}

  return (
		<header className="chatheader">
			<h1 className="chatheader__title h3">
				{truncate(props.place.name, 20)}
			</h1>
			<ul className="chatheader__list">
				<li>
					<Button
						classes={['chatheader__map']}
						event={(e) => toggleMap()}
						icon={iconMap}
						mods={['icon']}
						text="Map"
					/>
				</li>
				<li>
					<Button
						classes={['chatheader__menu']}
						event={(e) => toggleSidebar()}
						icon={iconMenu}
						mods={['icon', 'teal']}
						text="Menu"
					/>
				</li>
			</ul>
		</header>
  );
}

export default withRouter(ChatHeader);
