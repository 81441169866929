import React from 'react';
import { withRouter } from 'react-router-dom';

// components
import CardRoom from '../CardRoom/CardRoom.js';
import Map from '../Map/Map.js';

function ActiveRooms(props) {
	// props
	const { geolocation, isMapsLoaded, mapGeolocation, rooms, setSelectedRoom } = props;

	// prevent rendering if rooms is empty
  if(!rooms.length) {
    return false;
  }

	const createRooms = () => {
		if(rooms.length) {
			const roomCards = rooms.map((room, key) => {
		    return (
		      <CardRoom
						geolocation={geolocation}
						key={key}
						room={room}
						setSelectedRoom={setSelectedRoom}
					/>
		    );
		  });
			return roomCards;
		}
		return (
			<li className="card">
				There are currently no active chat rooms.
			</li>
		)
	}

  return (
    <section className="rooms section">
      <div className="wrap">
        <header className="rooms__header">
          <h2>Active Rooms</h2>
					<p className="rooms__intro intro">
						People are buzzing all over the place. Get in the know and join an active room to see what's going on.
					</p>
        </header>
				<Map
					hasHeader={true}
					geolocation={geolocation}
					isMapsLoaded={isMapsLoaded}
					markerTitle="You Are Here"
					lat={mapGeolocation.lat}
					lng={mapGeolocation.lng}
					markers={rooms}
					setSelectedRoom={setSelectedRoom}
				/>
        <ul className="rooms__list">
          {createRooms()}
        </ul>
      </div>
    </section>
  );
}

export default withRouter(ActiveRooms);
