import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// icons
import { ReactComponent as IconBinoculars } from '../../icons/binoculars.svg';
import { ReactComponent as IconComment } from '../../icons/comment.svg';
import { ReactComponent as IconHandshake } from '../../icons/handshake.svg';
import { ReactComponent as IconSuitcase } from '../../icons/suitcase.svg';

// css
import './Why.scss';

function Why(props) {

  useEffect(() => {
  }, []);

  return (
    <section className="why section section--teal">
      <div className="why__wrap wrap">
        <header className="why__header">
          <h2><strong>Find a Place. Join a Chat.</strong></h2>
          <p className="why__intro intro">
            Enter a chat to find out about a place or current event. If you're already there, meet someone new, coordinate plans,
            leave a review or just chat with others around you.
          </p>
        </header>
        <ul className="why__list">
          <li>
            <i className="i i--teal-dark"><IconBinoculars /></i>
            <div>Find Out What's Happening</div>
          </li>
          <li>
            <i className="i i--teal-dark"><IconHandshake /></i>
            <div>Meet Someone New</div>
          </li>
          <li>
            <i className="i i--teal-dark"><IconSuitcase /></i>
            <div>Leave a Review or Comment</div>
          </li>
          <li>
            <i className="i i--teal-dark"><IconComment /></i>
            <div>...or just chat!</div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default withRouter(Why);
