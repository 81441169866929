import React, { useState, useEffect, useRef } from 'react';

// components
import EmojiPicker from '../EmojiPicker/EmojiPicker.js';
import Button from '../Button/Button.js';

// utils
import { sanitizeMessage } from '../../utils.js';

// icons
import { ReactComponent as IconCamera } from '../../icons/material/camera-alt.svg';
import { ReactComponent as IconSend } from '../../icons/material/send.svg';
import { ReactComponent as IconMood } from '../../icons/material/mood.svg';

import './AddMessage.scss';

function AddMessage(props) {
	// refs
	const refFile = useRef();

  // state
  const [message, setMessage] = useState('');
  const [emoji, setEmoji] = useState({});
  const [emojiSelected, setEmojiSelected] = useState(false);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isSendBtnDisabed, setIsSendBtnDisabed] = useState(true);

  // refs
  const refTextInput = useRef();

	function onMessageChange(msg) {

    // update message
    setMessage(msg);

    // set is disabled
    let isDisabled = (msg.length) ? false : true;

    // toggle send btn
    setIsSendBtnDisabed(isDisabled);

		props.setIsTyping((msg.length));
	}

	async function getSanitizedMessage(message) {
		// sanitize message
		const messageSanitized = await sanitizeMessage(message);
		return messageSanitized;
	}

  function sendMessage(o) {
		if(o.e) {
    	o.e.preventDefault();
		}

    if(o.message.length) {

			getSanitizedMessage(o.message).then((data) => {

				const msgObj = {
					text: data.text,
					username: props.user.username,
					color: props.user.color,
					room: props.user.room,
					unfurl: (data.unfurl) ? JSON.stringify(data.unfurl) : null
				}

				props.addMessage(msgObj);

				// clear input
				setMessage('');
				props.setIsTyping(false);

				// if desktop
				if(window.matchMedia('(min-width: 1024px)').matches) {
					// focus input
					refTextInput.current.focus();
				}
			});
    }
  }

	function handleFile(e) {

		// vars
		const fr = new FileReader();
		const fileName = e.target.files[0].name;
		let img;

		// create image when loaded
		fr.onload = function () {
			img = `<img src="${fr.result}" alt="${fileName}" title="${fileName}" />`;

			sendMessage({
				message: img
			});
		}

		// read file
		fr.readAsDataURL(e.target.files[0]);
	}

  useEffect(() => {

    // if emoji is set
    if(emoji.unicode) {
      // if emoji selected is false
      if(!emojiSelected) {

        // convert unicode to integer
        let unicode = parseInt(emoji.unicode, 16);

        // convert unicode integer to string
        unicode = String.fromCodePoint(unicode);

        // set message with emoji appended
        setMessage((message) => `${message} ${unicode}`);

        // set emoji selected as true
        setEmojiSelected(true);

        // focus input
        refTextInput.current.focus();

				setIsSendBtnDisabed(false);
      }
    }
  }, [emoji.unicode, emojiSelected]);

  return (
    <section>
      <EmojiPicker
				emoji={emoji}
				isEmojiOpen={isEmojiOpen}
				setEmoji={setEmoji}
				setEmojiSelected={setEmojiSelected}
				setIsEmojiOpen={setIsEmojiOpen}
			/>
  		<form className="addmessage">
  			<div className="addmessage__text">
          <div>
						<input type="text" placeholder="What's on your mind?" value={message} ref={refTextInput} onChange={(e) => onMessageChange(e.target.value)} autoCapitalize="none" />
						<Button
							classes={['addmessage__addemoji']}
							event={(e) => setIsEmojiOpen(!isEmojiOpen)}
							icon={<IconMood />}
							type="button"
						/>
						<div className="addmessage__file">
							<label htmlFor="file__input">
								<IconCamera />
							</label>
							<input ref={refFile} name="file__input" id="file__input" className="file__input" type="file" accept="image/*" onChange={(e) => handleFile(e)} />
						</div>
					</div>
        </div>
  			<Button
					classes={['addmessage__btn']}
					disabled={isSendBtnDisabed}
					event={(e) => sendMessage({e: e, message: message})}
					icon={<IconSend />}
					mods={['orange']}
					text="Send"
					type="submit"
				/>
  		</form>
    </section>
  );
}

export default AddMessage;
