import React from 'react';
import { withRouter } from 'react-router-dom';

// styles
import './HomeScreen.scss';

function HomeScreen(props) {

  return (
    <section className="homescreen">
      <div className="homescreen__wrap wrap">
        <div className="homescreen__image">
					<img src="/img/apple-touch-icon-167x167.png" alt="Icon" />
				</div>
				<div className="homescreen__copy">
					<h3 className="homescreen__title">Add to Home Screen</h3>
					<p>Add ChatSpot to your home screen to use it just like you would any other app.</p>
				</div>
				<div className="homescreen__bar">
					<div>Add to Home Screen</div>
					<div className="bar__icon">
						<span>+</span>
					</div>
				</div>

				<div className="homescreen__android">
					<img src="/img/android-home-screen.png" alt="Add to Home Screen on Android" />
				</div>
      </div>
    </section>
  );
}

export default withRouter(HomeScreen);
