import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// icons
import { ReactComponent as IconFavorite } from '../../icons/material/favorite.svg';

// css
import './Footer.scss';

function Footer(props) {

  const today = new Date();

  return (
    <footer className="footer">
      <div className="wrap">
        <div className="footer__company">
          <div className="footer__copyright">
						<Link to="/" className="footer__logo">
							<img src="/img/icon.png" alt="Logo" loading="lazy" />
							{process.env.REACT_APP_NAME}
						</Link>
						<span>&copy; {today.getFullYear()}. {process.env.REACT_APP_TAGLINE}</span>
					</div>
					<ul className="footer__nav">
						<li>
							<Link to="/terms">Terms</Link>
						</li>
						<li>
							<Link to="/privacy">Privacy</Link>
						</li>
					</ul>
        </div>
        <div className="footer__credit">
          Made with <IconFavorite /> by <a href="https://michaelynch.com" target="_blank" rel="noopener noreferrer">Mike Lynch</a>
        </div>
      </div>
    </footer>
  );
}

export default withRouter(Footer);
