import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

// components
import Button from '../Button/Button.js';
import CardRoom from '../CardRoom/CardRoom.js';
import Map from '../Map/Map.js';

function NearbyRooms(props) {

	// props
	const { geolocation, getNearbyPlaces, isMapsLoaded, rooms, service, setSelectedRoom } = props;

	// state
	const [activeType, setActiveType] = useState(false);
	const [hasInitialRooms, setHasInitialRooms] = useState(false);

	const types = [
		{
			type: 'airport',
			label: 'Airports'
		},
		{
			type: 'amusement_park',
			label: 'Amusement Parks'
		},
		{
			type: 'art_gallery',
			label: 'Art Galleries'
		},
		{
			type: 'bar',
			label: 'Bars'
		},
		{
			type: 'beauty_salon',
			label: 'Beauty Salons'
		},
		{
			type: 'cafe',
			label: 'Cafes'
		},
		{
			type: 'movie_theater',
			label: 'Movie Theaters'
		},
		{
			type: 'school',
			label: 'Schools'
		},
		{
			type: 'shopping_mall',
			label: 'Shopping Malls'
		},
		{
			type: 'restaurant',
			label: 'Restaurants'
		},
		{
			type: 'transit_station',
			label: 'Transit Stations'
		}
	];

	const getNewRooms = useCallback((type, e) => {
		if(e) {
			e.preventDefault();
		}
		// reset selected room
		setSelectedRoom(null);

		// set active type
		setActiveType(type);

		// get nearby places by type
		getNearbyPlaces(type);
	}, [getNearbyPlaces, setSelectedRoom]);

  useEffect(() => {
		// if service
		if(service && geolocation && !hasInitialRooms) {
			setHasInitialRooms(true);
			getNewRooms('cafe');
		}
  }, [service, geolocation, hasInitialRooms, getNewRooms]);

	// prevent rendering if geolocation is empty
	if(!geolocation) {
		return false;
	}

	const createRooms = () => {
		if(rooms.length) {
		  const roomCards = rooms.map((room, key) => {
		    return (
	        <CardRoom
						geolocation={geolocation}
						key={key}
						room={room}
						setSelectedRoom={setSelectedRoom}
					/>
	      );
		  });
			return roomCards;
		}
		return (
			<li className="card">
				Sorry but we can't find any nearby rooms.
			</li>
		);
	}

	// loop through types
	const typesList = types.map((item, key) => {
		const mods = (item.type === activeType) ? ['pill', 'active'] : ['pill'];
		return (
			<li key={key} className="types__type">
				<Button type="button" mods={mods} text={item.label} event={(e) => getNewRooms(item.type, e)} />
			</li>
		);
	});

  return (
    <section className="rooms section">
      <div className="wrap">
        <header className="rooms__header">
          <h2>Nearby Rooms</h2>
          <p className="rooms__intro intro">
            Want to learn a little about your neighborhood? Or maybe you're travelling somewhere new? Check out some nearby places and ask the locals.
          </p>
					<ul className="rooms__types">
						{typesList}
					</ul>
        </header>
				<Map
					geolocation={geolocation}
					hasHeader={true}
					isMapsLoaded={isMapsLoaded}
					lat={geolocation.lat}
					lng={geolocation.lng}
					markers={rooms}
					setSelectedRoom={setSelectedRoom}
				/>
        <ul className="rooms__list">
          {createRooms()}
        </ul>
      </div>
    </section>
  );
}

export default withRouter(NearbyRooms);
