import React from 'react';

export function cleanHtml(content, el, classes) {
	if (!content) {
		return false;
	}

	const Tag = el ? el : 'p';
	const opts = {};

	// if classes were passed
	if (classes) {
		let elClasses = '';

		// loop through classes and construct string
		for (let i = 0; i < classes.length; i++) {
			elClasses += `${classes[i]} `;
		}

		// add class attribute to options object
		opts['className'] = elClasses;
	}

	return <Tag {...opts} dangerouslySetInnerHTML={createMarkup(content)} />;
}

export function constructRoom(place, type) {

	let room = {};

	if(place && type) {

		// define defaults
		let placeID = null;
		let placeType = null;
		let placeName = null;
		let placeViewport = null;
		let placeLat = null;
		let placeLng = null;
		let placeWebsite = null;
		let placeImage = null;
		let placeVenue = null;
		let placeEstablishment = null;

		let streetNumber = null;
		let route = null;
		let city = null;
		let state = null;
		let postalCode = null;
		let country = null;

		if(type === 'google') {

			// basic
			placeID = place.place_id;
			placeType = type;
			placeName = place.name;
			placeViewport = place.geometry.viewport;
			placeLat = place.geometry.location.lat();
			placeLng = place.geometry.location.lng();
			placeWebsite = place.website;
			placeImage = (place.photos) ? place.photos[0].getUrl({maxWidth: 600}) : '';
			placeEstablishment = (place.types) ? place.types[0] : null;

			if(place.plus_code) {
				const compoundCodeArray = place.plus_code.compound_code.split(' ');
				city = compoundCodeArray[1].replace(/,/g, '');
			}

			// address
			if(place.address_components) {

				for(let y = 0; y < place.address_components.length; y++) {

					if(place.address_components[y].types.includes('street_number')) {
						streetNumber = place.address_components[y].short_name;
					}

					if(place.address_components[y].types.includes('route')) {
						route = place.address_components[y].short_name;
					}

					if(place.address_components[y].types.includes('locality')) {
						city = place.address_components[y].short_name;
					}

					if(place.address_components[y].types.includes('administrative_area_level_1')) {
						state = place.address_components[y].short_name;
					}

					if(place.address_components[y].types.includes('postal_code')) {
						postalCode = place.address_components[y].short_name;
					}

					if(place.address_components[y].types.includes('country')) {
						country = place.address_components[y].short_name;
					}
				}
			}
		} else if(type === 'ticketmaster') {

			let venue = false;

			if(place._embedded) {

				venue = place._embedded.venues[0];
				placeWebsite = venue.url;
			}

			//basic
			placeID = place.id;
			placeType = type;
			placeName = place.name;
			placeViewport = null;
			placeEstablishment = 'event';

			placeImage = (place.images) ? place.images[0].url : null;

			if(venue) {
				placeVenue = venue.name;

				if(venue.location) {
					placeLat = Number(venue.location.latitude);
					placeLng = Number(venue.location.longitude);
				}

				if(venue.address) {

					if(venue.address.line1) {
						let addrArray = venue.address.line1.split(' ');
						route = venue.address.line1.replace(`${addrArray[0]} `, '');
						streetNumber = addrArray[0];
					}

					state = (venue.state) ? venue.state.stateCode : null;
					postalCode = (venue.postalCode) ? venue.postalCode : null;
					city = (venue.city) ? `${venue.city.name.charAt(0).toUpperCase()}${venue.city.name.slice(1).toLowerCase()}` : null;
					country = (venue.country) ? venue.country.name : null;
				}
			}
		}

		// construct room
		room = {
			id: placeID,
			type: placeType,
			establishment: placeEstablishment,
			name: placeName,
			created_at: Date.now(),
			address: {
				streetNumber: streetNumber,
				route: route,
				city: city,
				state: state,
				postal_code: postalCode,
				country: country
			},
			viewport: placeViewport,
			location: {
				lat: placeLat,
				lng: placeLng
			},
			website: placeWebsite,
			image: placeImage,
			venue: placeVenue,
			users: []
		};
	}

	return room;
}

export function createMarkup(content) {
	return {
		__html: content,
	};
}

async function fetchUnfurl(url) {
	// scrape url to get unfurl data
	const response = await fetch(`${process.env.REACT_APP_API_URL}/scrape/?url=${url}`, {
		method: 'get',
		mode: 'cors',
	});

	let responseJson = {};

	if(response) {
		// convert response into json
		responseJson = await response.json();
	}

	return responseJson;
}

export function getBrightness(hex) {

	// remove hash
	const c = hex.substring(1);

	// convert rrggbb to decimal
	const rgb = parseInt(c, 16);

	// extract red, green and blue
	const r = (rgb >> 16) & 0xff;
	const g = (rgb >>  8) & 0xff;
	const b = (rgb >>  0) & 0xff;

	// define luma per ITU-R BT.709 weighted factors
	const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

	return luma;
}

export function getCurrentTime() {
	const date = new Date();

	let mins = date.getMinutes();
	mins = (mins < 10) ? `0${mins}` : mins;

	let hours = date.getHours();
	const amOrPm = (hours < 12) ? 'AM' : 'PM';

	if(hours !== 12) {
		// convert hours to 12 hour clock
		hours = (hours < 12) ? hours : hours - 12;
	}

	const time = `${hours}:${mins} ${amOrPm}`;

	return time;
}

export function getDistance(lat1, lon1, lat2, lon2, unit) {

	const radlat1 = Math.PI * lat1 / 180;
	const radlat2 = Math.PI * lat2 / 180;
	const theta = lon1 - lon2;
	const radtheta = Math.PI * theta / 180;

	let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

	unit = (unit) ? unit : 'K';

	dist = Math.acos(dist);
	dist = dist * 180 / Math.PI;
	dist = dist * 60 * 1.1515;

	if(unit === 'K') {
		// convert miles to km
		dist = dist * 1.609344;
	}

	if(unit === 'N') {
		// convert miles to nautical miles
		dist = dist * 0.8684;
	}

	return dist;
};

export function getRandomColor(maxLuma) {
	const letters = '0123456789ABCDEF';
	let color = '#';

	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}

	// if max luma
	if(maxLuma) {
		// define color brightness
		const luma = getBrightness(color);

		// if luma is greater than max luma
		if(luma > maxLuma) {
			// redefine color as 40% darker shade
			color = shadeColor(color, -40);
		}
	}
	return color;
}

export function makeTitle(text) {
	// replace underscores with spaces
	let title = text.replace(/_/g, ' ').split(' ');

	title = title.map((word) => {
		return word.charAt(0).toUpperCase() + word.substring(1);
	});

  title = title.join(' ');

	return title;
}

export async function sanitizeMessage(message) {
	const messageUrls = [];
	let messageNew = message;
	let messageObj;
	let unfurlData;

	// if message has url
	if(messageNew.includes('http') || messageNew.includes('https')) {
		// vars
		const imageExtensions = ['gif', 'jpeg', 'jpg', 'png'];
		const messageArray = messageNew.split(' ');
		let messageWithLink = [];
		let messageImage = '';

		// for each word in message
		for(let i = 0; i < messageArray.length; i++) {
			// if message includes URL
			if(messageArray[i].includes('http') || messageArray[i].includes('https')) {
				// wrap word with anchor
				messageWithLink.push(`<a href="${messageArray[i]}" target="_blank" title="${messageArray[i]}">${messageArray[i]}</a>`);

				// check if message has image file extention
				const messageHasImage = imageExtensions.some((ext) => {
					return messageArray[i].includes(ext);
				});

				// define image element if message references image
				if(messageHasImage) {
					messageImage = `<a href="${messageArray[i]}" target="_blank" title="${messageArray[i]}"><img src="${messageArray[i]}" alt="${messageArray[i]}" /></a>`;
				} else {
					// add url to array
					messageUrls.push(messageArray[i]);
				}
			} else {
				// otherwise just add word
				messageWithLink.push(messageArray[i]);
			}
		}

		// add image if image exists
		if(messageImage) {
			messageWithLink.push(messageImage);
		}

		// redefine message with anchors
		messageNew = messageWithLink.join(' ');
	}

	// if message has urls in it
	if(messageUrls.length) {
		// fetch unfurl data for first url
		unfurlData = await fetchUnfurl(messageUrls[0]);
	}

	messageObj = {
		text: messageNew,
		unfurl: (unfurlData) ? unfurlData : null
	};

	return messageObj;
}

export function shadeColor(color, percent) {

	let RR;
	let GG;
	let BB;

	let R = parseInt(color.substring(1,3), 16);
	let G = parseInt(color.substring(3,5), 16);
	let B = parseInt(color.substring(5,7), 16);

	R = parseInt(R * (100 + percent) / 100);
	G = parseInt(G * (100 + percent) / 100);
	B = parseInt(B * (100 + percent) / 100);

	R = (R < 255) ? R : 255;
	G = (G < 255) ? G : 255;
	B = (B < 255) ? B : 255;

	RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
	GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
	BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));

	return `#${RR}${GG}${BB}`;
}

export function truncate(str, max) {
	max = (max) ? max : 10;
	return (str.length > max) ? `${str.slice(0, (max - 3))}...` : str;
};
