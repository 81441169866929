import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';

// components
import Button from '../Button/Button.js';

// icons
import { ReactComponent as IconAccessibility } from '../../icons/material/accessibility.svg';
import { ReactComponent as IconFlag } from '../../icons/material/flag.svg';
import { ReactComponent as IconHeadset } from '../../icons/material/headset.svg';
import { ReactComponent as IconMood } from '../../icons/material/mood.svg';
import { ReactComponent as IconNotInterested} from '../../icons/material/not-interested.svg';
import { ReactComponent as IconPets } from '../../icons/material/pets.svg';
import { ReactComponent as IconRestaurant } from '../../icons/material/restaurant.svg';
import { ReactComponent as IconRoom } from '../../icons/material/room.svg';
import { ReactComponent as IconSportsFootball } from '../../icons/material/sports-football.svg';

// styles
import './EmojiPicker.scss';

function EmojiPicker(props) {
	// props
	const { setIsEmojiOpen } = props;

	// state
	const [activeEmojiCategory, setActiveEmojiCategory] = useState(null);

  // data
  const emojisByCategory = require(`../../json/emojis.json`);

  // refs
  const refEmojiPicker = useRef();
  const refEmojiViewport = useRef();
  const refEmojiTabs = useRef();

  // icons by slug
  const icons = {
    activities: <IconSportsFootball />,
    animals: <IconPets />,
    flags: <IconFlag />,
    food: <IconRestaurant />,
    objects: <IconHeadset />,
    people: <IconMood />,
    skin: <IconAccessibility />,
    symbols: <IconNotInterested />,
    travel: <IconRoom />,
  };

  // vars
  let emojisTabs;
  let emojisCategories;

  function addEmoji(emojiItem) {
    props.setEmojiSelected(false);
    props.setEmoji(emojiItem);
    props.setIsEmojiOpen(false);
  }

  function changeEmojiCategory(e, categorySlug) {
		// update active emoji category
		setActiveEmojiCategory(categorySlug);
    // set viewport scrolltop to category offset minus tabs height and 20px gutter
    refEmojiViewport.current.scrollTop = (document.getElementById(`category-${categorySlug}`).offsetTop - refEmojiTabs.current.offsetHeight - 20);
  }

  useEffect(() => {

		function handleClick(e) {
	    if(refEmojiPicker.current) {
	      // if event is outside emoji picker and target is not an svg
	      if(!refEmojiPicker.current.contains(e.target) && !(e.target instanceof SVGElement)) {
	        // close emoji picker
	        setIsEmojiOpen(false);
	      }
	    }
	  }

    // add mousedown event to document
    document.addEventListener('mousedown', handleClick);

  }, [setIsEmojiOpen]);

  if(emojisByCategory) {

    // loop through emoji categories
	  emojisTabs = emojisByCategory.map((item, key) => {

			let btnMods = ['transparent'];

			if(item.slug === activeEmojiCategory) {
				btnMods.push('active');
			}

      return (
        <li className="tabs__item" key={key}>
          <Button
						classes={['item__btn']}
						event={(e) => changeEmojiCategory(e, item.slug)}
						mods={btnMods}
						icon={icons[item.slug]}
					/>
        </li>
      );
    });

	  // loop through emoji categories
	  emojisCategories = emojisByCategory.map((item, key) => {

      // loop through each emoji in category
      let emojisList = item.emojis.map((emojiItem, emojiKey) => {

        // if emoji has unicode
        if(emojiItem.unicode) {

          // convert unicode to integer
          let unicode = parseInt(emojiItem.unicode, 16);

          // convert unicode integer to string
          unicode = String.fromCodePoint(unicode);

          return (
            <li key={emojiKey}>
              <Button
								event={(e) => addEmoji(emojiItem)}
								mods={['emoji']}
								text={unicode}
							/>
            </li>
          );
        }

				return false;
      });

	    return (
        <li key={key} id={`category-${item.slug}`}>
          <h4 className="emojipicker__title">
            {item.category}
          </h4>
          <ul>
            {emojisList}
          </ul>
        </li>
      );
	  });
	}

  const classes = (props.isEmojiOpen) ? 'emojipicker emojipicker--active' : 'emojipicker';

  return (
    <section className={classes} ref={refEmojiPicker}>
      <ul className="emojipicker__tabs" ref={refEmojiTabs}>
        {emojisTabs}
      </ul>
      <div className="emojipicker__content">
        <div className="emojipicker__viewport" ref={refEmojiViewport}>
          <ul className="emojipicker__list">
            {emojisCategories}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default withRouter(EmojiPicker);
