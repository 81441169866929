import React from 'react';
import { withRouter } from 'react-router-dom';

// icons
import { ReactComponent as IconDandelion } from '../../icons/dandelion.svg';

// styles
import './Ephemeral.scss';

function Ephemeral(props) {

  return (
    <section className="ephemeral">
      <div className="ephemeral__wrap wrap">
        <div className="ephemeral__grid">
					<i className="ephemeral__icon i">
						<IconDandelion />
					</i>
					<div className="ephemeral__copy">
						<h2 className="ephemeral__title">Private and Temporary</h2>
						<p>The conversations you have in ChatSpot are automatically deleted as soon as you leave the chat. In fact, we're not even using a database! So rest assured, we will never store any of your conversations.</p>
					</div>
				</div>
      </div>
    </section>
  );
}

export default withRouter(Ephemeral);
