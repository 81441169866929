import React from 'react';

// components
import Button from '../Button/Button.js';

// icons
import { ReactComponent as IconExitToApp } from '../../icons/material/exit-to-app.svg';

// styles
import './Sidebar.scss';

function Sidebar(props) {
	// vars
	const sidebarActiveClass = (props.isSidebarOpen) ? ' sidebar--active' : '';
  let usersInRoom = [];

	// prevent rendering if place does not exist
	if(!props.place) {
		return false;
	}

	// loop through users
  const userList = props.users.map((user, key) => {
		if(user.room === props.place.id) {

			// define username styles
			let userStyles = {
				backgroundColor: user.color
			};
			let userClass = ((user.username === props.user.username) && props.isTyping) ? 'list__user list__user--active' : 'list__user';
      let distance = 'NA';

			if(user.distance) {
				if(user.distance < 10) {
					distance = `${Math.round(user.distance)} KM`;
				} else {
					distance = `${user.distance.toFixed(1)} KM`
				}
			}

      usersInRoom.push(user);

	    return (
				<li key={key} className={userClass}>
          <div>
						<div className="user__dot" style={userStyles}></div>
  					<div className="user__username">
							{user.username}
	  					<span className="user__typing">
	  						<span>.</span>
	  						<span>.</span>
	  						<span>.</span>
	  					</span>
						</div>
          </div>
          <div className="user__distance">
            <span className="distance__num">
              {distance}
            </span>
          </div>
				</li>
			);
		}

		return false;
  });

	const renderAvatar = () => {
		if(props.place.image) {
			return (
				<div className="sidebar__avatar" style={{backgroundImage: `url(${props.place.image})`}}></div>
			);
		}
		return false;
	}

	const renderAddress = () => {
		if(props.place.address) {
			if(props.place.address.streetNumber && props.place.address.route && props.place.address.city && props.place.address.state && props.place.address.postal_code) {
				return (
					<address className="sidebar__address">
						{props.place.address.streetNumber} {props.place.address.route}
						<br/>{props.place.address.city}, {props.place.address.state}
						<br/>{props.place.address.postal_code}
					</address>
				);
			}
		}
		return false;
	}

	const renderWebsiteBtn = () => {
		if(props.place.website) {
			return (
				<Button
					classes={['sidebar__url']}
					href={props.place.website}
					mods={['full', 'small', 'border', 'rock']}
					target="_blank"
					text="See Website"
				/>
			);
		}
		return false;
	}

  return (
    <section className={`sidebar${sidebarActiveClass}`}>
			<div className="sidebar__mask">
				{renderAvatar()}
	      <h1 className="sidebar__title h3">
					{props.place.name}
				</h1>
	      {renderAddress()}
				{renderWebsiteBtn()}

	      <div className="sidebar__users">
	        <h4 className="users__title h5">
	          Users ({usersInRoom.length})
	        </h4>
					<div className="users__mask">
		  			<ul className="users__list">
		  				{userList}
		  			</ul>
					</div>
	      </div>

				<ul className="sidebar__btns">
					<li>
						<Button
							classes={['sidebar__map']}
							event={(e) => props.setIsMapOpen(!props.isMapOpen)}
							mods={['full', 'small']}
							text={props.isMapOpen ? 'Close Map' : 'See Map'}
						/>
					</li>
					<li>
						<Button
							classes={['sidebar__exit']}
							event={(e) => props.leaveRoom(props.user, props.place)}
							href="/"
							icon={<IconExitToApp />}
							mods={['full', 'border', 'rock', 'small']}
							text="Leave Chat"
						/>
					</li>
				</ul>
			</div>
    </section>
  );
}

export default Sidebar;
