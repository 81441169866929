import React from 'react';
import { Link } from 'react-router-dom';

// styles
import './Button.scss';

function Button(props) {
	// vars
	const btnAttributes = {};
	let Tag = 'button';
	let classes = '';
	let mods = '';

	if(props.classes) {
		for(let i = 0; i < props.classes.length; i++) {
			classes += `${props.classes[i]} `;
		}
	}

	if(props.mods) {
		for(let i = 0; i < props.mods.length; i++) {
			mods += ` btn--${props.mods[i]}`;
		}
	}

	const renderIcon = () => {
		if(props.icon) {
			return (
				<span className="btn__icon">
					{props.icon}
				</span>
			);
		}
	}

	btnAttributes['className'] = `${classes}btn${mods}`;
	btnAttributes['disabled'] = props.disabled;

	if(props.href) {
		if(props.href.includes('http')) {
			Tag = 'a';
			btnAttributes['href'] = props.href;
		} else {
			Tag = Link;
			btnAttributes['to'] = props.href;
		}		

		btnAttributes['target'] = (props.target) ? props.target : '_self';

		if(btnAttributes['target'] === '_blank') {
			btnAttributes['rel'] = 'noopener noreferrer';
		}
	} else {
		btnAttributes['type'] = (props.type) ? props.type : 'button';
	}

	if(props.event) {
		btnAttributes['onClick'] = props.event;
	}

  return (
    <Tag {...btnAttributes}>
			{renderIcon()}
			<span className="btn__text">
				{props.text}
			</span>
		</Tag>
  );
}

export default Button;
