import React from 'react';
import { withRouter } from 'react-router-dom';

// img
import { ReactComponent as Map } from '../../img/map.svg';

// css
import './Mobile.scss';

function Mobile(props) {

  return (
    <section className="mobile section section--teal">
      <div className="mobile__map">
        <Map />
      </div>
      <div className="mobile__wrap wrap">
        <div className="mobile__device">
          <img src="/img/screen.png" alt="Device" loading="lazy" />
        </div>
        <div className="mobile__copy">
          <h2 className="mobile__title h1 divider">
            <strong>Join a Chat Whenever. Wherever.</strong>
          </h2>
          <p>Join a chat on your mobile device wherever you may be. You could ask someone a quick question or get some
          quick advice about the location you're at. No need to exchange phone numbers or any private information. Just join
          a chat with whatever username you want and you're good to go.</p>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Mobile);
