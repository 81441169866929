import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

// components
import ActiveRooms from '../../components/ActiveRooms/ActiveRooms.js';
import Ephemeral from '../../components/Ephemeral/Ephemeral.js';
import EventRooms from '../../components/EventRooms/EventRooms.js';
import Footer from '../../components/Footer/Footer.js';
import HomeScreen from '../../components/HomeScreen/HomeScreen.js';
import Login from '../../components/Login/Login.js';
import NearbyRooms from '../../components/NearbyRooms/NearbyRooms.js';
import Mobile from '../../components/Mobile/Mobile.js';
import Why from '../../components/Why/Why.js';

// utils
import { constructRoom } from '../../utils.js';

// styles
import './Home.scss';

function Home(props) {
	// props
	const { geolocation, isMapsLoaded, mapGeolocation, messages, nearbyPlaces, rooms, selectedRoom, setMessage, setNearbyPlaces, setMapGeolocation, setSelectedRoom, setUsername, setUsers, username, users } = props;

	// state
	const [service, setService] = useState(null);

	function constructNearbyPlaces(places) {

		const constructedPlaces = [];

		for(let i = 0; i < places.length; i ++) {
			constructedPlaces.push(constructRoom(places[i], 'google'));
		}

		// update nearby places
		setNearbyPlaces(constructedPlaces);
	}

	function onNearbyPlaces(results, status) {
		// if request was successful
		if(status === window.google.maps.places.PlacesServiceStatus.OK) {

			constructNearbyPlaces(results.slice(0, 8));
		} else {
			constructNearbyPlaces([]);
		}
	}

	function getNearbyPlaces(type) {

		if(service && geolocation) {

			const latLng = new window.google.maps.LatLng(geolocation.lat, geolocation.lng);

			// https://developers.google.com/places/supported_types

			service.nearbySearch({
				location: latLng,
				radius: '500',
				type: type,
			}, onNearbyPlaces);
		}
	}

  return (
    <section className="home">
      <Login
				geolocation={geolocation}
				getNearbyPlaces={getNearbyPlaces}
				isMapsLoaded={isMapsLoaded}
				messages={messages}
				nearbyPlaces={nearbyPlaces}
				selectedRoom={selectedRoom}
				service={service}
				setMessage={setMessage}
				setNearbyPlaces={setNearbyPlaces}
				setSelectedRoom={setSelectedRoom}
				setService={setService}
				setUsername={setUsername}
				setUsers={setUsers}
				username={username}
				users={users}
			/>
      <ActiveRooms
				geolocation={geolocation}
				isMapsLoaded={isMapsLoaded}
				mapGeolocation={mapGeolocation}
				rooms={rooms}
				selectedRoom={selectedRoom}
				setMapGeolocation={setMapGeolocation}
				setSelectedRoom={setSelectedRoom}
			/>
			<HomeScreen />
			<Why />
      <EventRooms
				geolocation={geolocation}
				isMapsLoaded={isMapsLoaded}
				mapGeolocation={mapGeolocation}
				selectedRoom={selectedRoom}
				setMapGeolocation={setMapGeolocation}
				setSelectedRoom={setSelectedRoom}
			/>
      <Ephemeral />
			<Mobile />
			<NearbyRooms
				geolocation={geolocation}
				getNearbyPlaces={getNearbyPlaces}
				isMapsLoaded={isMapsLoaded}
				rooms={nearbyPlaces}
				service={service}
				selectedRoom={selectedRoom}
				setSelectedRoom={setSelectedRoom}
			/>
      <Footer />
    </section>
  );
}

export default withRouter(Home);
