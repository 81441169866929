import React from 'react';

// components
import AddMessage from '../AddMessage/AddMessage.js';
import Unfurl from '../Unfurl/Unfurl.js';

// utils
import { cleanHtml, getCurrentTime } from '../../utils.js';

// styles
import './Viewport.scss';

function Viewport(props) {

	const createUnfurl = (unfurl) => {
		if(unfurl) {
			unfurl = JSON.parse(unfurl);
			return (
				<Unfurl url={unfurl.url} title={unfurl.title} description={unfurl.description} image={unfurl.image} />
			);
		}
	}

	let items = '';

	if(props.messages) {

	  // loop through chat messages
	  items = props.messages.map((msg, key) => {
			// define username styles
			let userStyles = {
				color: msg.color
			};

	    return (
				<li key={key} className="message">
					<div>
						<strong style={userStyles}>{msg.username}</strong>: {cleanHtml(msg.text, 'span')}
					</div>
					<div className="message__time">
						{getCurrentTime()}
					</div>
					{createUnfurl(msg.unfurl)}
				</li>
			);
	  });
	}

  return (
		<section className="viewport">
			<div className="viewport__chat">
				<ul className="viewport__list" ref={props.refViewportList}>
          {items}
				</ul>
			</div>
			<AddMessage user={props.user} place={props.place} addMessage={props.addMessage} setIsTyping={props.setIsTyping} />
		</section>
  );
}

export default Viewport;
