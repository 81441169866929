import React from 'react';
import { Link } from 'react-router-dom';

// icons
import { ReactComponent as IconChat } from '../../icons/material/chat.svg';

// img
import { ReactComponent as Logo } from '../../img/logo.svg';

// css
import './Header.scss';

function Header(props) {
  return (
    <header className="header">
      <div className="header__logo">
				<Link to="/" className="logo__a">
					<Logo /> <div className="logo__title h3">{process.env.REACT_APP_NAME}</div>
				</Link>
			</div>
			<nav className="header__nav">
				<ul>
					<li>
						<Link to="/">
							<IconChat />
							Join a Chat
						</Link>
					</li>
				</ul>
			</nav>
    </header>
  );
}

export default Header;
