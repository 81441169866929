import React, { useEffect, useState, useRef } from 'react';

// router
import { withRouter} from 'react-router-dom';

// utils
import { constructRoom } from '../../utils.js';
import Geohash from 'latlon-geohash';

// components
import CardRoom from '../CardRoom/CardRoom.js';
import Map from '../Map/Map.js';

// img
import { ReactComponent as LogoTicketmaster } from '../../img/logos/ticketmaster.svg';

function EventRooms(props) {
	// props
	const { geolocation, isMapsLoaded, mapGeolocation, setMapGeolocation, setSelectedRoom } = props;

	// refs
	const refSearch = useRef();
	const refAutocomplete = useRef();

	// state
  const [events, setEvents] = useState([]);
  const [hasEvents, setHasEvents] = useState(false);
	const [hasEventsByLocation, setHasEventsByLocation] = useState(false);
	const [isCityAutoComplete, setIsCityAutoComplete] = useState(false);

	// vars
	let ticketmasterURL;
	let date = new Date();

	// set hours
	date.setHours(0, 0, 0, 0);

	// convert date to iso without milliseconds (i.e. 2019-08-28T00:00:00Z)
	date = date.toISOString().split('.')[0]+'Z';

	ticketmasterURL = `https://app.ticketmaster.com/discovery/v2/events.json?apikey=${process.env.REACT_APP_TICKETMASTER_API_KEY}&size=8&sort=date,asc&startDateTime=${date}`;

	useEffect(() => {
		// if user geolocation is set
		if(geolocation) {
			// set map geolocation
			setMapGeolocation({
				lat: geolocation.lat,
				lng: geolocation.lng
			});
		}
	}, [geolocation, hasEventsByLocation, setMapGeolocation]);

  useEffect(() => {

    // vars
    let geohash;

		function getEvents(url) {
			// get ticketmaster events
			fetch(url, {mode: 'cors'}).then(function(response) {
				return response.json();
			}).then(function(data) {
				const events = [];

				if(data._embedded) {
					for(let i = 0; i < data._embedded.events.length; i++) {
						const event = constructRoom(data._embedded.events[i], 'ticketmaster');
						events.push(event);
					}
				}

				// set state
				setEvents(events);

			}).catch(function(error) {
				console.log(error);
			});
		}

		// if events are empty
		if(!hasEvents) {

			// set hasEvents so fetch only runs once
			setHasEvents(true);

			// get events
			getEvents(ticketmasterURL);
		}

		// if geolocation is set
		if(!hasEventsByLocation && geolocation) {

			// set setHasEventsByLocation so fetch only runs once
			setHasEventsByLocation(true);

			// convert lat and lng to geohash
			geohash = Geohash.encode(geolocation.lat, geolocation.lng, 9);

			// add geohash to ticketmaster api url
			const ticketmasterURLNew = `${ticketmasterURL}&geoPoint=${geohash}`;

			// get new events by location
			getEvents(ticketmasterURLNew);
		}

		if(isMapsLoaded) {

			if(!isCityAutoComplete) {

				setIsCityAutoComplete(true);

				const autocompleteOptions = {
					types: ['(cities)']
				};

				// define autocomplete input
				refAutocomplete.current = new window.google.maps.places.Autocomplete(refSearch.current, autocompleteOptions);

				// add autocomplete listener
				refAutocomplete.current.addListener('place_changed', function() {
					// define place
					const place = refAutocomplete.current.getPlace();

					if(place.address_components) {

						let city;
						let countryCode;

						for(let i = 0; i < place.address_components.length; i++) {
							if(place.address_components[i].types.includes('locality')) {
								city = place.address_components[i].long_name;
							}

							if(place.address_components[i].types.includes('country')) {
								countryCode = place.address_components[i].short_name;
							}

							if(city && countryCode) {
								break;
							}
						}

						// add geohash to ticketmaster api url
						const ticketmasterURLNew = `${ticketmasterURL}&city=${city}&countryCode=${countryCode}`;

						setMapGeolocation({
							lat: place.geometry.location.lat(),
							lng: place.geometry.location.lng()
						});

						// get new events by city and country
						getEvents(ticketmasterURLNew);
					}
				});
			}
		}

  }, [geolocation, hasEvents, hasEventsByLocation, isCityAutoComplete, isMapsLoaded, setMapGeolocation, ticketmasterURL]);

	const createRooms = () => {
		if(events.length) {
		  const roomCards = events.map((room, key) => {
		    return (
	        <CardRoom
						geolocation={geolocation}
						key={key}
						room={room}
						setSelectedRoom={setSelectedRoom}
					/>
	      );
		  });
			return roomCards;
		}
		return (
			<li className="card">
				Sorry but we can't find any upcoming events.
			</li>
		);
	}

  return (
    <section className="rooms rooms--events section">
      <div className="rooms__wrap wrap">
        <header className="rooms__header rooms__header--search">
					<div>
		        <h2>Upcoming Events</h2>
		        <p className="rooms__intro intro">
		          Attending an event today? Find the venue and join the chat. You can find out details in real time, ask a question or meet someone new.
		        </p>
					</div>
					<div>
						<label>Search</label>
						<input className="events__search" type="text" placeholder="Enter City" ref={refSearch} />
					</div>
        </header>
				<Map
					hasHeader={true}
					isMapsLoaded={isMapsLoaded}
					lat={mapGeolocation.lat}
					lng={mapGeolocation.lng}
					markers={events}
					setSelectedRoom={setSelectedRoom}
				/>
        <ul className="rooms__list">
          {createRooms()}
        </ul>
				<footer className="rooms__footer">
					<div className="footer__credit">
						provided by <a href="https://ticketmaster.com" target="_blank" rel="noopener noreferrer"><LogoTicketmaster /></a>
					</div>
				</footer>
      </div>
    </section>
  );
}

export default withRouter(EventRooms);
