import React from 'react';
import { withRouter} from 'react-router-dom';

// utils
import { getDistance, makeTitle } from '../../utils.js';

// icons
import { ReactComponent as IconChat } from '../../icons/material/chat.svg';
import { ReactComponent as IconLocationCity } from '../../icons/material/location-city.svg';
import { ReactComponent as IconRoom } from '../../icons/material/room.svg';

// img
import { ReactComponent as Logo } from '../../img/logo.svg';

import './CardRoom.scss';

function CardRoom(props) {
	// props
	const { geolocation, key, room, setSelectedRoom } = props;

	// vars
	let establishment;
	let location;
	let cardClassEmpty = '';

	const renderCardImage = () => {
		if(room.image) {
			return (
				<img src={room.image} alt="Room" loading="lazy" />
			);
		} else {
			return <i><Logo /></i>;
		}
	};

	const renderCardIcons = () => {
		if(room.users.length) {
			return (
				<ul className="card__icons">
					<li title={`${room.users.length} Active Users`}>
						<IconChat />
						<span>{room.users.length}</span>
					</li>
				</ul>
			);
		}
		return false;
	}

	const renderCardDistance = () => {
		if(geolocation) {
			let distance = getDistance(geolocation.lat, geolocation.lng, room.location.lat, room.location.lng);

			distance = (distance > 10) ? Math.round(distance) : distance.toFixed(1);

			return (
				<div className="card__distance" title={`${distance} KM Away`}>
					<div className="card__icon"><IconRoom /></div>
					{distance} KM
				</div>
			);
		}
	};

	function handleClick(e, room) {
		setSelectedRoom(room);
		window.scrollTo(0, 0);
	}

  if(!room) {
		return false;
	}

	if(room.address) {
		if(room.address.city) {
			location = room.address.city;
		}
	}

	if(room.establishment) {
		establishment = makeTitle(room.establishment);
	}

	if(!room.image) {
		cardClassEmpty = ' card--noimage';
	}

  return (
		<li key={key} className={`card${cardClassEmpty}`} title={`${room.users.length} Users in ${room.name}`}>
			<button className="card__btn" onClick={(e) => handleClick(e, room)}>
				<div className="card__image">
					{renderCardImage()}
				</div>
				<div className="card__content">
					<header className="card__header">
						<h3 className="card__title">
							<div className="card__strapline">{establishment}</div>
							{room.name}
						</h3>
						{renderCardIcons()}
					</header>
					<footer className="card__footer">
						<div className="card__location" title={location}>
							<div className="card__icon"><IconLocationCity /></div> {location}
						</div>
						{renderCardDistance()}
					</footer>
				</div>
			</button>
		</li>
  );
}

export default withRouter(CardRoom);
