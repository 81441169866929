import React from 'react';

// styles
import './Unfurl.scss';

function Unfurl(props) {

	if(!props.title) {
		return false;
	}

	const createDescription = () => {
		if(props.description) {
			return (
				<div className="unfurl__description">
					{props.description}
				</div>
			);
		}
	}

	const createImage = () => {
		if(props.image) {
			return (
				<div className="unfurl__image">
					<a href={props.url} target="_blank" title={props.title} rel="noopener noreferrer">
						<img className="image__img" src={props.image} alt={props.url} />
					</a>
				</div>
			);
		}
	}

  return (
		<div className="unfurl">
			<div className="unfurl__grid">
				<div className="unfurl__copy">
					<h3 className="unfurl__title h4">
						<a href={props.url} target="_blank" title={props.title} rel="noopener noreferrer">
							{props.url}
						</a>
						{props.title}
					</h3>
					{createDescription()}
				</div>
				{createImage()}
			</div>
		</div>
  );
}

export default Unfurl;
