import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

// components
import Button from '../Button/Button.js';

// utils
import { constructRoom, getDistance, getRandomColor } from '../../utils.js';

// img
import { ReactComponent as Logo } from '../../img/logo.svg';

// styles
import './Login.scss';

// vars
let map;

function Login(props) {
	// props
	const { geolocation, isMapsLoaded, match, setService, selectedRoom, setSelectedRoom, username, setUsername } = props;

	// refs
	const refLoginSection = useRef();
	const refLoginMap = useRef();
  const refSearchInput = useRef();
	const refUsernameInput = useRef();

	// state
	const [btnDisabled, setBtnDisabled] = useState('disabled');
	const [isMapCreated, setIsMapCreated] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [place, setPlace] = useState(null);

  function onSearch(e) {
    e.preventDefault();
    setBtnDisabled('disabled');
  }

	useEffect(() => {

		let autocomplete;

		function updatePlace(place, status) {

			if(status === window.google.maps.places.PlacesServiceStatus.OK) {

				const room = constructRoom(place, 'google');

				// remove selected room
				setSelectedRoom(null);

				// update state
				setPlace(room);

				// center map on location
				if(map && place.geometry.viewport) {
					map.fitBounds(place.geometry.viewport);
				} else {
					map.setCenter(place.geometry.location);
					map.setZoom(17);
				}

				refUsernameInput.current.focus();
			}
		}

		function onMapsLoad() {

			// construct map
			map = new window.google.maps.Map(refLoginMap.current, {
				mapTypeControlOptions: {
		      mapTypeIds: []
		    },
				streetViewControl: false,
				center: {
					lat: (geolocation) ? geolocation.lat : 43.6532,
					lng: (geolocation) ? geolocation.lng : -79.3832
				},
				zoom: 15,
				scrollwheel: false
			});

			// define service
			const service = new window.google.maps.places.PlacesService(map);

			// set service
			setService(service);

			// define autocomplete input
			autocomplete = new window.google.maps.places.Autocomplete(refSearchInput.current);

			// add autocomplete listener
			autocomplete.addListener('place_changed', function() {
				// define place
				const place = autocomplete.getPlace();

				// if place ID exists
				if(place.place_id) {

					// get place details
					service.getDetails({
						placeId: place.place_id
					}, updatePlace);

				} else {
					setBtnDisabled('disabled');
				}
			});

			// if slug is in URL
			if(match.params.slug) {
				// replace hyphens with spaces
				const place = match.params.slug.replace(/-/g, ' ');

				// search place to get name and place id
				service.findPlaceFromQuery({query: place, fields: ['name', 'place_id']}, (results, status) => {
			    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
						// set search input value to place name
						refSearchInput.current.value = results[0].name;

						// get place details
						service.getDetails({
							placeId: results[0].place_id
						}, updatePlace);
			    }
			  });
			}
		}

		// load google maps api
    if(isMapsLoaded && !isMapCreated) {
      onMapsLoad();
			setIsMapCreated(true);
    }

		if(selectedRoom) {

			// center map on selected location
			if(map && selectedRoom.viewport) {
				map.fitBounds(selectedRoom.viewport);
			} else {
				map.setCenter(selectedRoom.location);
				map.setZoom(17);
			}

			if(refSearchInput.current) {
				// set search value as place name
				refSearchInput.current.value = selectedRoom.name;
			}

			if(refUsernameInput.current) {
				// focus username input
				refUsernameInput.current.focus();
			}

			if(refLoginSection.current) {

				// scroll to top of document
		    refLoginSection.current.scrollTo(0, 0);
			}

      // update state
			setPlace(selectedRoom);
		} else {
			// center map on geolocation
			if(map && geolocation) {
				map.setCenter({
					lat: geolocation.lat,
					lng: geolocation.lng
				});
			}
		}

		if(username && place) {
			setBtnDisabled('');
		} else {
			setBtnDisabled('disabled');
		}

	}, [geolocation, isMapsLoaded, match, setService, selectedRoom, setSelectedRoom, isMapCreated, username, place]);

	// if place is set
	if(isReady && username && place) {

		const userGeolocation = (geolocation) ? geolocation : null;
		const userDistance = (geolocation) ? getDistance(geolocation.lat, geolocation.lng, place.location.lat, place.location.lng) : null;

		const userObj = {
			username: username,
			color: getRandomColor(128),
			room: place.id,
			place: place,
			location: userGeolocation,
			distance: userDistance
		};

		// redirect to chat
		return (
			<Redirect
        to={{
          pathname: `/chat/${place.id}`,
          state: {
						place: JSON.stringify(place),
						user: JSON.stringify(userObj)
					}
        }}
      />
		);
	}

  return (
    <section className="login section section--short" ref={refLoginSection}>
      <div className="login__map" ref={refLoginMap}></div>
      <div className="login__wrap wrap">
        <div className="login__box">
					<header className="login__header">
	          <h1 className="login__title h2">
	            <Logo /> {process.env.REACT_APP_NAME}
	          </h1>
					</header>
          <form className="login__form">
						<p>Find a place, choose a username and join a chat.</p>
            <ul>
              <li>
                <input type="text" className="login__search" id="login__search" placeholder="Find a place" ref={refSearchInput} onClick={(e) => onSearch(e)} autoCapitalize="none" />
              </li>
							<li>
                <input type="text" className="login__username" id="login__username" placeholder="Enter a Username" ref={refUsernameInput} onChange={(e) => setUsername(e.target.value)} autoCapitalize="none" />
              </li>
              <li>
								<Button
									classes={['login__btn']}
									disabled={btnDisabled}
									event={(e) => setIsReady(true)}
									mods={['full']}
									text="Join Chat"
									type="submit"
								/>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Login);
