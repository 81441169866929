import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import io from 'socket.io-client';

// components
import Home from './pages/Home/Home.js';
import Chat from './pages/Chat/Chat.js';
import Page from './pages/Page/Page.js';

// styles
import './App.scss';

function App() {

	// vars
	const socket = io(`${process.env.REACT_APP_API_URL}`);
	const content = require(`./json/en.json`);

	// state
	const [users, setUsers] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [messages, setMessages] = useState([]);
	const [geolocation, setGeoLocation] = useState(null);
	const [isMapsLoaded, setIsMapsLoaded] = useState(false);
	const [mapGeolocation, setMapGeolocation] = useState({lat: 37.0902, lng: -95.7129});
	const [nearbyPlaces, setNearbyPlaces] = useState([]);
	const [notificationsEnabled, setNotificationsEnabled] = useState(false);
	const [username, setUsername] = useState(null);

	function showPosition(position) {
		setGeoLocation({
			lng: position.coords.longitude,
			lat: position.coords.latitude
		});
	}

	useEffect(() => {

		// get user location
		if(navigator.geolocation) {
	    navigator.geolocation.getCurrentPosition(showPosition);
	  }

		// if browser supports notifications
		if(('Notification' in window)) {

			// if notifications are enabled
			if(Notification.permission === "granted") {
				setNotificationsEnabled(true);

			} else {
				// request permission for notifications
				Notification.requestPermission().then(function (permission) {
		      // if the user accepts
		      if(permission === 'granted') {
						setNotificationsEnabled(true);
		      }
		    });
			}
	  }

		// listen for get users
    socket.on('get_users', function(users, rooms) {

			// set users
			setUsers(users);

			// set rooms
			setRooms(rooms);
    });

		// get users
		socket.emit('get_users');

		// close socket before unload
		window.addEventListener('beforeunload', (e) => {
			socket.close();
		});

		return () => {
			socket.off('get_users');
		}

  }, []);

	useEffect(() => {

		// load google maps api
		if(!window.google) {
			const script = document.createElement(`script`);
			const headScript = document.getElementsByTagName(`script`)[0];

			script.type = `text/javascript`;
			script.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

			headScript.parentNode.insertBefore(script, headScript);
			script.addEventListener(`load`, function() {
				setIsMapsLoaded(true);
			});
		} else {
			setIsMapsLoaded(true);
		}

	}, []);

  return (
    <BrowserRouter>
      <main>
				<Switch>
	        <Route exact path={['/', '/place/:slug?']}>
						<Home
							geolocation={geolocation}
							isMapsLoaded={isMapsLoaded}
							username={username}
							setUsername={setUsername}
							users={users}
							setUsers={setUsers}
							mapGeolocation={mapGeolocation}
							messages={messages}
							rooms={rooms}
							setMessages={setMessages}
							selectedRoom={selectedRoom}
							setSelectedRoom={setSelectedRoom}
							nearbyPlaces={nearbyPlaces}
							setNearbyPlaces={setNearbyPlaces}
							setMapGeolocation={setMapGeolocation}
						/>
					</Route>
	        <Route exact path="/chat/:id?">
						<Chat
							isMapsLoaded={isMapsLoaded}
							socket={socket}
							users={users}
							setUsers={setUsers}
							mapGeolocation={mapGeolocation}
							messages={messages}
							setMessages={setMessages}
							notificationsEnabled={notificationsEnabled}
							setSelectedRoom={setSelectedRoom}
						/>
					</Route>
					<Route exact path="/privacy">
						<Page content={content.pages.privacy} />
					</Route>
					<Route exact path="/terms">
						<Page content={content.pages.terms} />
					</Route>
					<Route>
						<Page content={content.pages.notFound} />
					</Route>
				</Switch>
      </main>
    </BrowserRouter>
  );
}

export default App;
